import React, { useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Splash from "../components/Splash"
import Cta from "../components/Cta"
import Ready from "../components/Ready"
import DetailedSteps from "../components/DetailedSteps"
import FundraisingShort from "../components/FundraisingShort"

import { LocationContext } from "../contexts/LocationContext"

export const PromoPostTemplate = ({
  title,
  subtitles,
  promoCode,
  promoCodeText,
  detailedSteps,
  fundraisingShortData,
  isCms
}) => (
  <>
    <Splash
      title={title}
      subtitles={subtitles}
      promoCode={promoCode}
      promoCodeText={promoCodeText}
      ctaScroll="#detailed-steps"
    />
    <DetailedSteps data={detailedSteps} id="detailed-steps" />
    {isCms ? null :
      <FundraisingShort data={fundraisingShortData} />
    }
    <Ready svg>
      <Cta href="https://app.re-collect.com.au/register">Get Started</Cta>
      <Cta to="/contact" variant>
        Contact us
      </Cta>
    </Ready>
  </>
)

const PromoPost = ({
  data: {
    markdownRemark: {
      frontmatter: {
        tag,
        title,
        subtitles,
        promoCode,
        promoCodeText,
        seoDesc,
        detailedSteps,
        fundraisingShortTitle,
      },
    },
  },
}) => {
  const { loc } = useContext(LocationContext)

  let fundraisingShortData = {
    title: fundraisingShortTitle,
    loc: {
      city: loc.city,
      area: loc.area
    }
  }

  return (
    <Layout title={tag} seoDesc={seoDesc}>
      <PromoPostTemplate
        title={title}
        subtitles={subtitles}
        promoCode={promoCode}
        promoCodeText={promoCodeText}
        detailedSteps={detailedSteps}
        fundraisingShortData={fundraisingShortData}
      />
    </Layout>
  )
}

export default PromoPost

export const PageQuery = graphql`
  query promotionQuery ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        tag
        title
        subtitles    
        promoCode
        promoCodeText
        seoDesc
        detailedSteps {
          title
          subtitle
          data {
            title
            body
          }
          video
        }
        fundraisingShortTitle
      }
    }
  }
`
